import React from 'react';
import './styles.scss';

const Shrimmer = () => {
  const transactions = [1];
  return (
    <div className='app-shrimmer'>
      {(transactions.length) ?
        (<>{transactions.map((item, index) => (<>
          <div className='flex-column table-det'>
            <div className="custom-shimmer row-d" />
          </div>
        </>
        ))}</>) : <></>}
    </div>
  )
}
export default Shrimmer;